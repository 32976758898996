import { Experience } from "./experience";
import { Projects } from "./projects";
import {About} from './about'
import {
  BasicInformation,
  Blog,
  Achievements,
  Skills,
  Testimonials,
  Education,
} from "./aside-content";

export function Body() {
  return (
    <div className="container sections-wrapper">
      <div className="row">
        <div className="primary col-lg-8 col-12">
          <About />
          <Projects />
          <Experience />
        </div>
        <div className="secondary col-lg-4 col-12">
          <BasicInformation />
          <Skills />
          <Testimonials />
          <Education />
          <Blog />
          <Achievements />
        </div>
      </div>
    </div>
  );
}
