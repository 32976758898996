export function Experience() {
  return (
    <section className="experience section">
      <div className="section-inner">
        <h2 className="heading">Work Experience</h2>
        <div className="content">
          <div className="item">
            <h3 className="title">
              Full Stack Engineer-{" "}
              <span className="place">
                <a href="#">Contrast Security Inc.</a>
              </span>{" "}
              <span className="year">(2019 - Present)</span>
            </h3>
            <p>
              Designed and developed software solutions in various languages
              implementing the best design practices. Collaborated with a
              hybrid-agile team practicing scrum. Lead cross-functional
              discussions with the objective of finding solutions to software
              problems.
            </p>
          </div>
          <div className="item">
            <h3 className="title">
              Customer Care Consultant -{" "}
              <span className="place">
                <a href="https://www.protechassociates.com/?gclid=Cj0KCQjw7sDlBRC9ARIsAD-pDFqGsMcthkQY94JS0Js8ixCHnmJq-VpWgi9Jg9NBsvSJwDbJkRUNkfYaAmFuEALw_wcB">
                  Protech Associates
                </a>
              </span>{" "}
              <span className="year">(2018)</span>
            </h3>
            <p>
              Collaborated with a team of 5 in an Agile environment to manage
              customer support for over 150 small and large businesses. Trained
              business leaders and executives on Protech Software, an expanded
              version of Microsoft Dynamics 360 CRM. Troubleshot and resolved
              critical bugs reducing outsourcing by 80 percent. Created
              instructional videos for business and employee use on the
              technology.
            </p>
          </div>
          <div className="item">
            <h3 className="title">
              Tax Intern -{" "}
              <span className="place">
                <a href="https://www.mccormickcorporation.com/">
                  McCormick &amp; Company
                </a>
              </span>{" "}
              <span className="year">(2016 - 2017)</span>
            </h3>
            <p>
              Prepared corporate tax documents, such as Schedule K-1, using MS
              Excel and CorpTax software. Utilized multiple financial software
              such as Adobe Hyperion to analyze Balance Sheets and Profit/Loss
              statements. Operated SAP to examine specific expense accounts
              (Meals &amp; Entertainment &amp; Political Contributions) for
              reporting purposes.
            </p>
          </div>
        </div>
      </div>
    </section>
  );
}
