export function BasicInformation() {
  return (
    <aside className="info aside section">
      <div className="section-inner">
        <h2 className="heading sr-only">Basic Information</h2>
        <div className="content">
          <ul className="list-unstyled">
            <li>
              <i className="fas fa-map-marker-alt"></i>
              <span className="sr-only">Location:</span>Laurel, MD
            </li>
            <li>
              <i className="fas fa-envelope"></i>
              <span className="sr-only">Email:</span>
              <a href="mailto:joequattrone5@gmail.com">
                joequattrone5@gmail.com
              </a>
            </li>
            <li>
              <i className="fas fa-link"></i>
              <span className="sr-only">Website:</span>
              <a href="#">https://www.joequattrone.com</a>
            </li>
          </ul>
        </div>
      </div>
    </aside>
  );
}

export function Skills() {
  return (
    <aside className="skills aside section">
      <div className="section-inner">
        <h2 className="heading">Skills</h2>
        <div className="content">
          <p className="intro">
           Extensive experience with frontend and backend development in multiple
            languages and frameworks.
          </p>

          <div className="skillset">
            <div className="item">
              <h3 className="level-title">
                Java &amp; Spring
                <span
                  className="level-label"
                  data-toggle="tooltip"
                  data-placement="left"
                  data-animation="true"
                  title="Main technology used at Contrast Security"
                >
                  <i className="fas fa-info-circle"></i>Advanced
                </span>
              </h3>
              <div className="level-bar">
                <div className="level-bar-inner" data-level="96%"></div>
              </div>
            </div>

            <div className="item">
              <h3 className="level-title">
                Javascript &amp; React
                <span
                  className="level-label"
                  data-toggle="tooltip"
                  data-placement="left"
                  data-animation="true"
                  title="Found in nearly every code base"
                >
                  <i className="fas fa-info-circle"></i>Advanced
                </span>
              </h3>
              <div className="level-bar">
                <div className="level-bar-inner" data-level="93%"></div>
              </div>
            </div>

            <div className="item">
              <h3 className="level-title">
                mySQL
                <span
                  className="level-label"
                  data-toggle="tooltip"
                  data-placement="left"
                  data-animation="true"
                  title="More than just SELECT statements"
                >
                  <i className="fas fa-info-circle"></i>Proficient
                </span>
              </h3>
              <div className="level-bar">
                <div className="level-bar-inner" data-level="85%"></div>
              </div>
            </div>

            <div className="item">
              <h3 className="level-title">
                Ruby on Rails
                <span
                  className="level-label"
                  data-toggle="tooltip"
                  data-placement="left"
                  data-animation="true"
                  title="Learned in Flatiron school and is used in many of my projects"
                >
                  <i className="fas fa-info-circle"></i>Proficient
                </span>
              </h3>
              <div className="level-bar">
                <div className="level-bar-inner" data-level="85%"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </aside>
  );
}

export function Testimonials() {
  return (
    <aside className="testimonials aside section">
      <div className="section-inner">
        <h2 className="heading">Testimonials</h2>
        <div className="content">
          <div className="item">
            <blockquote className="quote">
              <p>
                <i className="fas fa-quote-left"></i>Joe goes above and beyond
                what's asked for him for every project he's worked on. He also
                helps his peers around him. Amazing student!
              </p>
            </blockquote>
            <p className="source">
              <span className="name">Enoch</span>
              <br />
              <span className="title">Flatiron School Instructor</span>
            </p>
          </div>

          <p>
            <a
              className="more-link"
              href="https://www.linkedin.com/in/joe-quattrone-47382b103/"
            >
              <i className="fas fa-external-link-alt"></i>More on Linkedin
            </a>
          </p>
        </div>
      </div>
    </aside>
  );
}

export function Education() {
  return (
    <aside className="education aside section">
      <div className="section-inner">
        <h2 className="heading">Education</h2>
        <div className="content">
          <div className="item">
            <h3 className="title">
              <i className="fas fa-graduation-cap"></i> Full Stack Web
              Development
            </h3>
            <h4 className="university">
              Flatiron School <span className="year">(2018-2019)</span>
            </h4>
          </div>
          <div className="item">
            <h3 className="title">
              <i className="fas fa-graduation-cap"></i> BSc Business
              Administration - Finance
            </h3>
            <h4 className="university">
              Towson University <span className="year">(2013-2017)</span>
            </h4>
          </div>
        </div>
      </div>
    </aside>
  );
}

export function Blog() {
  return (
    <aside className="blog aside section">
      <div className="section-inner">
        <h2 className="heading">Recent Blog Posts</h2>
        <div className="item">
          <h3 className="title">
            <a
              href="https://medium.com/@joequattrone5/rails-javascipt-flatiron-school-project-4-5d4bdbe21d3c"
              target="_blank"
            >
              Rails &amp; JavaScipt — Flatiron School Project #4
            </a>
          </h3>
          <div>
            <p>
              For the fourth project in the Flatiron School Online Web Immersive
              program, we were required to build off of our previous Ruby on
              Rails project and implement JavaScript into it...
            </p>
            <a
              className="more-link"
              href="https://medium.com/@joequattrone5/rails-javascipt-flatiron-school-project-4-5d4bdbe21d3c"
              target="_blank"
            >
              <i className="fas fa-external-link-alt"></i>Read more
            </a>
          </div>
        </div>

        <div className="item">
          <h3 className="title">
            <a
              href="https://medium.com/@joequattrone5/my-ruby-on-rails-project-for-flatiron-school-ab525e6972ee"
              target="_blank"
            >
              My Ruby on Rails Project for Flatiron School
            </a>
          </h3>
          <div>
            <p>
              In the Flatiron school program, for our third project, we were
              required to build a web application with Rails. I will link to my
              proje...
            </p>
            <a
              className="more-link"
              href="https://medium.com/@joequattrone5/my-ruby-on-rails-project-for-flatiron-school-ab525e6972ee"
              target="_blank"
            >
              <i className="fas fa-external-link-alt"></i>Read more
            </a>
          </div>
        </div>
        <div className="item">
          <h3 className="title">
            <a
              href="https://medium.com/@joequattrone5/another-one-down-c0db260eee2d"
              target="_blank"
            >
              Another One Down{" "}
            </a>
          </h3>
          <div>
            <p>
              I finished my second project as a web developer this past week.
              The first project I created wasn’t a web application. It was a
              Ruby gem called ‘UFC Schedule’...
            </p>
            <a
              className="more-link"
              href="https://medium.com/@joequattrone5/another-one-down-c0db260eee2d"
              target="_blank"
            >
              <i className="fas fa-external-link-alt"></i>Read more
            </a>
          </div>
        </div>

        <div className="item">
          <h3 className="title">
            <a
              href="https://medium.com/@joequattrone5/the-beginners-guide-to-self-in-ruby-2ed5d683d8a9"
              target="_blank"
            >
              The Beginner’s Guide to ‘Self’ in Ruby
            </a>
          </h3>
          <div>
            <p>
              If you’re new to object-oriented programing, the concept of ‘self’
              may seem foreign to you. This article will take a deep dive at the
              concept of self and explain wha...
            </p>
            <a
              className="more-link"
              href="https://medium.com/@joequattrone5/the-beginners-guide-to-self-in-ruby-2ed5d683d8a9"
              target="_blank"
            >
              <i className="fas fa-external-link-alt"></i>Read more
            </a>
          </div>
        </div>
      </div>
    </aside>
  );
}

export function Achievements() {
  return (
    <aside className="list music aside section">
      <div className="section-inner">
        <h2 className="heading">Honors &amp; Achievements</h2>
        <div className="content">
          <ul className="list-unstyled">
            <li>
              <i className="fas fa-trophy"></i>
              <a
                style={{ color: "#434343" }}
                href="https://youtu.be/U9f8zqvSJEk?t=53"
                target="_blank"
              >
                {" "}
                Honorary Instructor - Flatiron School{" "}
              </a>
            </li>
            <li>
              <i className="fas fa-trophy"></i> Treasurer - Tau Kappa Epsilon
              Fraternity
            </li>
            <li>
              <i className="fas fa-trophy"></i> Dean's List Student - Towson
            </li>
            <li>
              <i className="fas fa-trophy"></i> 4x Employee of The Week -
              MarketSource
            </li>
          </ul>
        </div>
      </div>
    </aside>
  );
}
