import lyricBunny from "../../../images/lyricbunny.png";
import jTravel from "../../../images/j_travel_img.png";
import jingle from "../../../images/jingle.png";
import ufcSchedule from "../../../images/ufc_schedule.png";

export function Projects() {
  return (
    <section className="latest section">
      <div className="section-inner">
        <h2 className="heading">Latest Projects</h2>
        <section className="content">
          <div className="item featured text-center">
            <h3 className="title">
              <a href="https://j-travel.herokuapp.com/" target="_blank">
                J-Travel
              </a>
            </h3>
            <p className="summary">
              A beautiful hotel-booking website that uses real data to mock the
              functionality of Hotels.com
            </p>
            <div className="featured-image has-ribbon">
              <a href="https://j-travel.herokuapp.com/" target="_blank">
                <img src={jTravel} className="img-fluid project-image" />
              </a>
              <div className="ribbon">
                <div className="text">New</div>
              </div>
            </div>

            <div className="desc text-left">
              <p>
                On J-Travel you can search for hotels in any city in the world
                and find real, available hotels. You can then book a hotel (not
                really) and view it on the 'My Trips' page of your profile.
                J-Travel offers many more features and is a fast, compact
                application built with Ruby on Rails and Javascript.
              </p>
            </div>
           <div style={{display: "flex", justifyContent: "center"}}>
            <div style={{marginRight: "5px"}}>
            <a
              className="btn btn-cta-secondary"
              href="https://j-travel.herokuapp.com/"
              target="_blank"
            >
              View my project
            </a>
            </div>
            <div>
            <a
              id="ghub-btn"
              style={{
                backgroundColor: "#3AAA64",
                borderColor: "#3AAA64",
              }}
              className="btn btn-cta-secondary"
              href="https://github.com/JoeQuattrone/j_travel"
              target="_blank"
            >
              Github
            </a>
            </div>
           </div>
          </div>

          <hr className="divider" />

          <div className="item row">
            <a
              className="col-md-4 col-12"
              href="https://lyricbunny.herokuapp.com/"
              target="_blank"
            >
              <img src={lyricBunny} className="img-fluid project-image" />
            </a>
            <div className="desc col-md-8 col-12">
              <h3 className="title">
                <a href="https://lyricbunny.herokuapp.com/" target="_blank">
                  LyricBunny - Find The Lyrics To Your Favorite Songs
                </a>
              </h3>
              <p className="mb-2">
                LyricBunny allows users to search for and like the lyrics of
                their favorite songs and it displays the most liked songs on the
                homepage of the website. Built with React, Redux, and Ruby on
                Rails.
              </p>
              <p>
                <a
                  className="more-link"
                  href="https://lyricbunny.herokuapp.com/"
                  target="_blank"
                >
                  <i className="fas fa-external-link-alt"></i>View project
                </a>
                <a
                  className="more-link ghub-link"
                  href="https://github.com/JoeQuattrone/lyricbunny-client"
                  target="_blank"
                >
                  <i className="fab fa-github fa-lg"></i>Github
                </a>
              </p>
            </div>
          </div>

          <div className="item row">
            <a
              className="col-md-4 col-12"
              href="https://my-jingle.herokuapp.com/"
              target="_blank"
            >
              <img src={jingle} className="img-fluid project-image" />
            </a>
            <div className="desc col-md-8 col-12">
              <h3 className="title">
                <a href="https://my-jingle.herokuapp.com/" target="_blank">
                  jingle - Financial Planning Application
                </a>
              </h3>
              <p className="mb-2">
                To get started sign up or login in from this test account.
                Login: 'test@test.com', password: 'test'. With jingle, users set
                a monetary goal and this app calculates and presents exactly how
                to get there.{" "}
              </p>
              <p>
                <a
                  className="more-link"
                  href="https://my-jingle.herokuapp.com/"
                  target="_blank"
                >
                  <i className="fas fa-external-link-alt"></i>View project
                </a>
                <a
                  className="more-link ghub-link"
                  href="https://github.com/JoeQuattrone/jingle"
                  target="_blank"
                >
                  <i className="fab fa-github fa-lg"></i>Github
                </a>
              </p>
            </div>
          </div>

          <div className="item row">
            <a
              className="col-md-4 col-12"
              href="https://rubygems.org/gems/ufc_schedule"
              target="_blank"
            >
              <img src={ufcSchedule} className="img-fluid project-image" />
            </a>
            <div className="desc col-md-8 col-12">
              <h3 className="title">
                <a
                  href="https://rubygems.org/gems/ufc_schedule"
                  target="_blank"
                >
                  UFC Schedule - Ruby Gem
                </a>
              </h3>
              <p className="mb-2">
                With over 2,000 downloads and counting, this powerful Ruby gem
                brings the UFC Schedule to your command line. Built with OO
                Ruby, This gem scrapes with Nokogiri and uses the UFC API to
                collect data.
              </p>
              <p>
                <a
                  className="more-link"
                  href="https://rubygems.org/gems/ufc_schedule"
                  target="_blank"
                >
                  <i className="fas fa-external-link-alt"></i>View project
                </a>
                <a
                  className="more-link ghub-link"
                  href="https://github.com/JoeQuattrone/ufc_schedule"
                  target="_blank"
                >
                  <i className="fab fa-github fa-lg"></i>Github
                </a>
              </p>
            </div>
          </div>
        </section>
      </div>
    </section>
  );
}
