import profPic from "../../../images/prof_pic.png";

export function Header() {
  return (
    <header className="header">
      <div className="container clearfix">
        <img src={profPic} className="profile-image img-fluid float-left" />
        <div className="profile-content float-left">
          <h1 className="name">Joe Quattrone</h1>
          <h2 className="desc">Software Engineer</h2>
          <ul className="social list-inline">
            <li className="list-inline-item">
              <a href="https://github.com/JoeQuattrone" target="_blank">
                <i className="fab fa-github"></i>
              </a>
            </li>
            <li className="list-inline-item">
              <a
                href="https://www.linkedin.com/in/joe-quattrone-47382b103/"
                target="_blank"
              >
                <i className="fab fa-linkedin-in"></i>
              </a>
            </li>
            <li className="list-inline-item">
              <a
                href="https://www.facebook.com/joe.quattrone.7"
                target="_blank"
              >
                <i className="fab fa-facebook"></i>
              </a>
            </li>
            <li className="list-inline-item last-item">
              <a href="https://medium.com/@joequattrone5" target="_blank">
                <i className="fab fa-medium"></i>
              </a>
            </li>
          </ul>
        </div>
        <button className="btn btn-cta-primary float-right">
          <i className="fas fa-arrow-down"></i>
          <span>View Resume</span>
        </button>
      </div>
    </header>
  );
}
