export function About() {
    return(
        <section className="about section">
            <div className="section-inner">
              <h2 className="heading">About Me</h2>
              <div className="content">
                <p>
                  Welcome, I'm a Software Engineer with experience in Java,
                  JavaScript, React, mySQL, and general web-development. I have
                  a degree in Finance from Towson University, but I discovered
                  my passion for Software Development post-graduation. I love to
                  code and my passion seeps through to every project with which
                  I am involved. I have built and maintained many web
                  applications and you can find some of them on this site.
                </p>
                <p>
                  <a href="mailto:joequattrone5@gmail.com">Shoot me an email</a>{" "}
                  if you're interested in working with me.
                </p>
              </div>
            </div>
          </section>
    )
}