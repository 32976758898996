import React from "react";

import { Helmet } from "react-helmet";
import { Header } from "./components/header";
import { Body } from "./components/body";
import "../../styles/home.css";
//delete me
export function Home() {
  return (
    <>
      <Helmet>
        <script
          type="text/javascript"
          src="//cdnjs.cloudflare.com/ajax/libs/mustache.js/0.7.2/mustache.min.js"
        ></script>
        <script
          defer
          src="https://use.fontawesome.com/releases/v5.7.2/js/all.js"
          integrity="sha384-0pzryjIRos8mFBWMzSSZApWtPl/5++eIfzYmTgBBmXYdhvxPc+XcFEk+zJwDgWbP"
          crossOrigin="anonymous"
        />
        <script
          type="text/javascript"
          src="../../github-activity-0.1.5.min.js"
        />
        <script
          type="text/javascript"
          src="../../vendor/github-calendar.min.js"
        />

        <script
          src="https://code.jquery.com/jquery-3.3.1.min.js"
          integrity="sha256-FgpCb/KJQlLNfOu91ta32o/NMZxltwRo8QtmkMRdAu8="
          crossOrigin="anonymous"
        />
        <script
          type="text/javascript"
          src="https://cdn.usebootstrap.com/bootstrap/4.3.1/js/bootstrap.min.js"
        />
        <script type="text/javascript" src="/src/vendor/main.js" />
      </Helmet>

      <Header />
      <Body />
    </>
  );
}
